import { faChevronDown, faClose, faHamburger, faHome, faInfoCircle, faMicrophone, faNewspaper, faPhone, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import stl from "../../../assets/site-logo.png";
import "../../css/web/header.css";
import "@fontsource/poppins";
import { Link } from 'react-router-dom';

export default function Header({pageName, screenWidth, browser}) {
    const [isMobile, setIsMobile] = useState(false);
    const [page, setPage] = (pageName);
useEffect(() => {

    if(screenWidth < 700){
        setIsMobile(!isMobile)
    }
}, [screenWidth])
  return (
    <div id='header'>
        <div className='web-head'>
            <div className='logo-box'>
                <img src={stl} alt='Site-logo'/>
            </div>
            <ul id='desktop'>
                <Link to={"/"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faHome} /></div> Home </li></Link>
                <Link to={"/briefings"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faNewspaper} /></div> Briefings Room</li></Link>
                <Link to={"/about"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faInfoCircle} /></div> About Us</li></Link>
                <Link to={"/contact"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faPhone} /></div> Contact</li></Link>
            </ul>
            {
                isMobile === true ? (
                        <div onClick={(e) => {
                            document.querySelector(".mobile-menu").style.display = "flex"
                        }} id="menuToggle">
                            <input type="checkbox" />
                              <span></span>
                              <span></span>
                              <span></span>
                            
                        </div>
                  ) : null
            }
            <div onClick={(e) => {
                            document.querySelector(".mobile-menu").style.display = "none"
                        }} className='mobile-menu'>
                <div  style={{width: "40px", height: "40px", color: "#000", position: "absolute", right: "10px", top: "10px", zIndex: "100000", fontSize: "20px"}}>
                   <FontAwesomeIcon onClick={(e) => {
                            document.querySelector(".mobile-menu").style.display = "none"
                        }} icon={faClose} />
                </div>
                <ul id='mobile'>
                    <div className='mobile-search'>
                       <FontAwesomeIcon icon={faSearch} />
                       <input type='text' placeholder='Search here' />
                    </div>
                    <Link to={"/"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faHome} /></div> Home </li></Link>
                    <Link to={"/briefings"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faNewspaper} /></div> Briefings Room</li></Link>
                    <Link to={"/about"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faInfoCircle} /></div> About Us</li></Link>
                    <Link to={"/contact"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faPhone} /></div> Contact</li></Link>
                    <Link to={"/portal"}><li><div className='icon-wrapper'><FontAwesomeIcon icon={faPhone} /></div> Register</li></Link>
                </ul>
            </div>
            <div className='search-box'>
                <FontAwesomeIcon icon={faSearch} />
                <input type='text' placeholder='Search here' />
            </div>
        </div>
        <div className='services-bar'>
            <div className='sv_1'>
                <p>Business Registration</p>
                <FontAwesomeIcon style={{width: 12, height: 12}} icon={faChevronDown} />
            </div>
            <div className='sv_1'>
                <p>Liscense Application</p>
                <FontAwesomeIcon style={{width: 12, height: 12}} icon={faChevronDown} />
            </div>
            <div className='sv_1'>
                <p>Importation</p>
                <FontAwesomeIcon style={{width: 12, height: 12}} icon={faChevronDown} />
            </div>
            <div className='sv_1'>
                <p>Track Your Process</p>
                <FontAwesomeIcon style={{width: 12, height: 12}} icon={faChevronDown} />
            </div>
            <div className='sv_1'>
                <p>Verify A Business</p>
                <FontAwesomeIcon style={{width: 12, height: 12}} icon={faChevronDown} />
            </div>
        </div>
    </div>
  )
}
