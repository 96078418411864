import { width } from '@fortawesome/free-solid-svg-icons/fa0'
import React from 'react'

export default function NewsBox({title, src, time, date}) {
    const style ={
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "300px",
        height: "450px",
        backgroundColor: "#fff",
        marginBottom: "25px"
    }
    const img ={
        width: "300px",
        height: "150px",
        objectFit: "cover",
        objectPosition: "top"
    }
    const p ={
        fontSize: "15px",
        color: "orangeRed",
    }
    const h2 ={
        fontSize: "25px",
        marginBottom: "10px",
        lineHeight: "1.1"
    }
    const excerp ={
        display: "flex",
        gap: "10px",
        width: "-webkit-fill-available",
        alignItems: "center",
        JustifyContent: "space-between",
    }
  return (
    <div style={style} id='article-box'>
        <img style={img} src={src} />
        <h2 style={h2}>{title}</h2>
        <div style={excerp} className='excerp'>
           <p style={p}>{date}</p>
           <p style={p}>{time}</p>
        </div>
    </div>
  )
}
