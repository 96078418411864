import logo from './logo.svg';
import "@fontsource/poppins";
import './App.css';
import Home from './_web/global/home';
import About from './_web/global/about';
import Contact from './_web/global/contact';
import Briefings from './_web/global/posts';
import Portal from './_web/global/registration';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/Briefings' element={<Briefings />} />
      <Route path='/portal' element={<Portal />} />
    </Routes>
  );
}

export default App;
