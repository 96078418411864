import React from 'react';
import "../css/web/briefings.css"
import Header from './components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import NewsBox from './components/newsBox';
import Footer from './components/footer';
import { Link } from 'react-router-dom';

export default function Briefings() {
    var page = "Briefings";
    var screen = window.innerWidth;
  return (
    <div id='briefings'>
        <Header pageName={page} screenWidth={screen} />
        <div className='container'>
            <div className='trending'>
                <img src={require("../../assets/petrol.jpg")} />
                <div className='trnd-txt'>
                    <h1>Government announces price reduction in petroleum products</h1>
                    <p>The Ministry of Commerce & Industry (MoCI) has announced downward adjustment in the prices of petroleum products on the Liberian market effective October 4, 2024.</p>
                    <a>Read More <FontAwesomeIcon icon={faArrowRightLong} /></a>
                </div>
            </div>
            <div className='news-board'>
                <h2>Most Recent</h2>
                <div className='news'>
                     <NewsBox title={"Government announces price reduction in petroleum products"} src={require("../../assets/ganta-tour.jpg")} time={"8pm"} date={"0ct. 12"}/>
                     <NewsBox title={"Government announces price reduction in petroleum products"} src={require("../../assets/ganta-tour.jpg")} time={"8pm"} date={"0ct. 12"}/>
                     <NewsBox title={"Government announces price reduction in petroleum products"} src={require("../../assets/ganta-tour.jpg")} time={"8pm"} date={"0ct. 12"}/>
                     <NewsBox title={"Government announces price reduction in petroleum products"} src={require("../../assets/ganta-tour.jpg")} time={"8pm"} date={"0ct. 12"}/>
                </div>

            </div>
        </div>
        <Footer />
    </div>
  )
}
