import React from 'react'
import Header from './components/header';
import "../css/web/contact.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocation, faLocationArrow, faLocationPin, faLocationPinLock, faMailForward, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/footer';
import { Link } from 'react-router-dom';

export default function Contact() {
    var page = "contact";
    var screen = window.innerWidth;
  return (
    <div id='contact'>
        <Header pageName={page} screenWidth={screen}/>
        <div className='main-cnt'>
            <h1>Contact Us</h1>
            <p>If you have comments, concerns, questions, or other feedback you would like to give us, feel free to contact us through any of the methods listed below.</p>
            <div className='contact-container'>
                <div className='contacts-box'>
                    <div className='contact'><div className='icon-box'><FontAwesomeIcon icon={faPhone} /></div><p>(+231)777999444</p></div>
                    <div className='contact'><div className='icon-box'><FontAwesomeIcon icon={faEnvelope} /></div><p>moci@gov.lr</p></div>
                    <div className='contact'><div className='icon-box'><FontAwesomeIcon icon={faLocationPinLock} /></div><p>Ministerial Complex - Congo Town . Monrovia, Republic of Liberia</p></div>
                </div>
                <div className='contact-form'>
                    <h2>Send Us Message</h2>
                    <input type='text' placeholder='Full Name' />
                    <input type='email' placeholder='Email Address' />
                    <textarea cols={40} placeholder='Your Message' />
                    <a>Send Message <FontAwesomeIcon icon={faPaperPlane} /></a>
                </div>
           </div>
        </div>
        <Footer />
    </div>
  )
}
