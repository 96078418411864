import React, { useState } from 'react'
import "./css/form.css";
import teacher from "../../../assets/teacher.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong, faBriefcase, faBuilding, faBus, faDriversLicense, faShuttleVan, faTaxi, faTractor, faTruck, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import SlideButton from './boxButtonSlide';
import AddINC from './form3';

export default function App_Form() {
    const [newReg, setNewReg] = useState(false)
    const [newLcs, setNewLcs] = useState(false)
    const [btnSelected, setBtnselected] = useState(false)
    const [index, setIndex] = useState(0)
    const options = [
        {title: "Select what you're applying for"},
        {title: "Vehicle Registration"},
        {title: "Renew Registration"},
        {title: "Driver's License"},
    ]
    function nextQuestion(e) {
        setIndex(index + 1)
        console.log(index);
        
    }
    

    const NewRegistration = () => {
        if(index === 0){
            return(
                <div className='slide-box'>
                        <h2>Select your car type Below</h2>
                        <div id='btn-slide'>
                            <SlideButton name={"Taxi"} btIcon={faTaxi} question={"Type of vehicle"} callFunction={nextQuestion} />
                            <SlideButton name={"Bus"} btIcon={faBus} question={"Type of vehicle"} callFunction={nextQuestion} />
                            <SlideButton name={"Heavy"} btIcon={faTruck} question={"Type of vehicle"} callFunction={nextQuestion} />
                        </div>
                        <div className='btn-box'>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }}>Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 1){
            return(
                <div className='slide-box'>
                        <h2>Enter Your Car Info</h2>
                        <div id='input-box'>
                            <label>Vehicle Model</label>
                            <input type='text' placeholder='e.g Toyota Corolla 2006' />

                            <label>VIN:</label>
                            <input type='text' placeholder='e.g 4L53TR792G60S34' />

                            <label>Body Color</label>
                            <input type='text' placeholder='e.g Blue' />

                            <label>Car photo</label>
                            <input type='file' />
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 2){
            return(
                <div className='slide-box'>
                        <h2>Owner's Info</h2>
                        <div id='input-box'>
                            <label>Owner Full Name</label>
                            <input type='text' placeholder='e.g Peter Brown' />

                            <label>Gender</label>
                            <select className='select'>
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>

                            <label>Address</label>
                            <input type='number' placeholder='Sinkor' />

                            <label>County of Residence</label>
                            <select className='select'>
                                <option>County reside currently</option>
                            </select>
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 3){
            return(
                <div className='slide-box'>
                        <h2>Supporting Documents</h2>
                        <div id='input-box'>
                            <label>Owner's Passport sized photo</label>
                            <input type='file' />

                            <label>National Identity</label>
                            <input type='file' />
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 4){
            return(
                <div className='slide-box'>
                        <h2>Preview</h2>
                        <div id='input-box'>
                        <h4 style={{marginBottom: "5px"}}>Vehicle Info</h4>
                            <p>Vehicle Type: Taxi</p>
                            <p>VIN: 4l789O4P43517H24</p>
                            <p>Model: Toyota Corolla v6</p>
                            <p>YOM: 2007</p>
                            <p>Color: Blue</p>
                            <p>Seats: 5</p>
                            <p>Purpose: Private</p>
                            <p>Car photos: (recieved)</p>
                            
                            <h4 style={{margin: "14px 0 5px"}}>Owner's Info</h4>
                            <p>Registered to: James Brown</p>
                            <p>County: Montserrado</p>
                            <p>Gender: Male</p>
                            <p>Photo & ID: (recieved)</p>
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Submit <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
    }

    const NewLicense = () => {
        if(index === 0){
            return(
                <div className='slide-box'>
                        <h2>Select your car type Below</h2>
                        <div id='btn-slide'>
                            <SlideButton name={"Regular"} btIcon={faDriversLicense} question={"Type of License"} callFunction={nextQuestion} />
                            <SlideButton name={"Chufeur"} btIcon={faShuttleVan} question={"Type of License"} callFunction={nextQuestion} />
                            <SlideButton name={"Heavy"} btIcon={faTruck} question={"Type of License"} callFunction={nextQuestion} />
                        </div>
                        <div className='btn-box'>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }}>Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 1){
            return(
                <div className='slide-box'>
                        <h2>Driver's Details</h2>
                        <div id='input-box'>
                            <label>Frist Name</label>
                            <input type='text' placeholder='e.g Peter' />

                            <label>Last Name/Family Names</label>
                            <input type='text' placeholder='e.g Williams' />

                            <label>Middle Name (If Any)</label>
                            <input type='text' placeholder='e.g Johnson' />

                            <label>Height</label>
                            <input type='text' />
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 2){
            return(
                <div className='slide-box'>
                        <h2>Background Info</h2>
                        <div id='input-box'>
                            <label>Date of Birth</label>
                            <input type='date' placeholder='' />

                            <label>Gender</label>
                            <select className='select'>
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>

                            <label>Address</label>
                            <input type='number' placeholder='Sinkor' />

                            <label>County of Residence</label>
                            <select className='select'>
                                <option>County reside currently</option>
                            </select>
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 3){
            return(
                <div className='slide-box'>
                        <h2>Background Info - page 2</h2>
                        <div id='input-box'>
                            <label>Place of Birth</label>
                            <input type='text' placeholder='e.g SDA Hospital' />

                            <label>County of Origin</label>
                            <select className='select'>
                                <option>Select</option>
                                <option>Margibi</option>
                                <option>Montserrado</option>
                            </select>

                            <label>City of Orign</label>
                            <input type='text' placeholder='e.g Monrovia' />

                            <label>County of Residence</label>
                            <select className='Nationality'>
                                <option>Citizenship</option>
                            </select>
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 4){
            return(
                <div className='slide-box'>
                        <h2>Supporting Documents</h2>
                        <div id='input-box'>
                            <label>Driver's Passport sized photo</label>
                            <input type='file' />

                            <label>National Identity</label>
                            <input type='file' />
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Next <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
        if(index === 5){
            return(
                <div className='slide-box'>
                        <h2>Preview</h2>
                        <div id='input-box'>
                        <h4 style={{marginBottom: "5px"}}>Vehicle Info</h4>
                            <p>License Type: Ordinary</p>
                            <p>First Name: James</p>
                            <p>Last Name: Williams</p>
                            <p>Birth place: Paynesville hospital</p>
                            <p>City of Origin: Buchana</p>
                            <p>County of Origin: Grand Bassa</p>
                            <p>Seats: 5</p>
                            <p>Purpose: Private</p>
                            <p>Driver's photos: (recieved)</p>
                            
                        </div>
                        <div className='btn-box'>
                        <a id='back' onClick={(e) => {
                            setIndex(index - 1)
                            localStorage.setItem("fm-idx", index);
                            }}><FontAwesomeIcon icon={faArrowLeftLong}/> Previous</a>
                           <a onClick={(e) => {
                               setIndex(index + 1)
                               localStorage.setItem("fm-idx", index);
                            }} >Submit <FontAwesomeIcon icon={faArrowRightLong}/></a>
                        </div>
                </div>
            )
        }
    }
  return (
    <div id='app_form'>
        <div className='form_box'>
            <div className='img-section'>
                <div className='img-tagline'>
                    <img src={require("../../../assets/assistant-min.jpg")} />
                    <span>Hon. Blessing Dahn - Ass. Minister</span>
                </div>
                <div className='quote-box'>
                    <p>Welcome to our registration portal. Kindly proceed with all attentiveness in entering your details. Application once submitted cannot be edited or changed. And the ministry won't be held responsible for any mispels or mistakes detected on a submited application</p>
                </div>
            </div>
            <form>
                <div className='svg-box'>
                    <img src={teacher} />
                    <p>Choose your options</p>
                </div>
                <select className='apply-type' onChange={(e) => {
                    if(e.currentTarget.options[e.currentTarget.selectedIndex].text === "Vehicle Registration"){
                        setNewReg(true)
                    } else {
                        setNewReg(false)
                    }
                    if(e.currentTarget.options[e.currentTarget.selectedIndex].text === "Driver's License"){
                        setNewLcs(true)
                    } else {
                        setNewLcs(false)
                    }
                    }} id='businessType'>
                    {
                        options.map(option => {
                            return(
                                <option>{option.title}</option>
                            )
                        })
                    }
                </select>
                {
                    newReg === true? <NewRegistration /> :
                    newLcs === true? <NewLicense /> : null
                }
            </form>
        </div>
    </div>
  )
}
