import React from 'react';
import "./css/top.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faInfoCircle, faLocation, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export default function TopBar() {
  return (
    <div id='topBar'>
        <div className='top-head'>
            <div className='logo-box'>
                <div className='png-bg'>
                   <img src={require("../../../assets/logo.png")} />
                </div>
                <div className='bereau-name'>
                    <h1>Ministry Of Commerce & Industry</h1>
                    <p>Liberia Business Registry</p>
                </div>
            </div>    

            <div className='page-info'>
                <h1>Business Registration</h1>
                <p>Applicant Entry System</p>
            </div>
        </div>
        <div className='nav-box'>
            <ul>
                <li><span><FontAwesomeIcon icon={faEdit} /> Apply</span></li>
                <li><span><FontAwesomeIcon icon={faLocation} /> Track Application</span></li>
                <li><span><FontAwesomeIcon icon={faInfoCircle} /> Help</span></li>
            </ul>

            <span id='qq'><FontAwesomeIcon icon={faQuestionCircle} /> Questions</span>
        </div>
    </div>
  )
}
