import React from 'react'
import "../../css/web/footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import facebook from "../../../assets/facebook.svg";
import twitter from "../../../assets/twitter.svg";
import instagram from '../../../assets/instagram.svg';
import whatsapp from "../../../assets/whatsapp.svg";

export default function Footer() {
  return (
    <div id='footer'>
        <div className='main'>
            <div className='menu'>
                <p>Resources</p>
                <ul>
                    <li>The History of Ministry of Commerce and Industry</li>
                    <li>The Liberian Chambers of Commerce</li>
                    <li>Small Buisness and startups enterprises program</li>
                    <li>Processing Time</li>
                </ul>
            </div>
            <div className='menu'>
                <p>Customer Service</p>
                <ul>
                    <li>Buisness Records Request</li>
                    <li>Payment Process</li>
                    <li>Report Unlawful Activities</li>
                    <li>Chat with us</li>
                </ul>
            </div>
            <div className='menu'>
                <p>MOCI Initiatives</p>
                <ul>
                    <li>Research Studies & Reports</li>
                    <li>Policies and programs</li>
                    <li>Ministry Performance</li>
                    <li>Bereaus at MOCI</li>
                </ul>
            </div>
        </div>
        <div className='bottom-bar'>
            <span>CopyRight &copy; 2024 Ministry Of Commerce</span>
            <p>Privacy policy statement</p>
            <p>Terms of use</p>
            <p>Accesibility</p>
            <div className='social'>
                <img src={facebook} />
                <img src={whatsapp} />
                <img src={twitter} />
                <img src={instagram} />
            </div>
        </div>
    </div>
  )
}
