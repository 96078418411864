import React from 'react'
import "./css/top.css"

export default function BottomBar() {
  return (
    <div id='bottom'>
      <span>&copy; CopyRight Ministry of Commerce & Industry 2024</span>
      <span>This portal is operated by the Liberia Business Registry</span>
    </div>
  )
}
