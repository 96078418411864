import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

export default function SlideButton({name, btIcon, question, callFunction}) {
  function handle() {
    localStorage.setItem(question, name);
    callFunction(true)
  }
  return (
    <button onClick={(e) => {
      e.preventDefault()
      let active = document.getElementById("active")
      if(active === null){
        e.currentTarget.setAttribute("id", "active");
      } else{
        active.setAttribute("id", "")
        e.currentTarget.setAttribute("id", "active");
      }
      localStorage.setItem(question, name);
     // callFunction(true)
    }} id={name} className='slideButton'>
        <FontAwesomeIcon icon={btIcon} />
        <p>{name}</p>
    </button>
  )
}
