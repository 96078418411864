import React from 'react';
import "../css/web/home.css"
import Header from './components/header';
import Banner from './components/banner';
import TipVideoBox from './components/tipVideoBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/footer';
import { Link } from 'react-router-dom';

export default function Home() {
    let vid = ""
    var page = "Home";
    var screen = window.innerWidth;
    var videos = [
        {title: "Easy Online Registration", subs: "Register your business online without visiting our office", src: "https://www.youtube.com/embed/29F5gZsD4wQ?si=Lwc2sS4BpUcX1DTQ&amp;"},
        {title: "Apply for your import permit", subs: "Importation isn't always that difficult.", src: "https://www.youtube.com/embed/rJmJwwPWdIE?si=-aXHVM4urBo3qfEK&amp;"}
    ]
  return (
    <div id='home'>
        <Header screenWidth={screen} pageName={page} />
        <Banner screen={screen} pageName={page} />
        <main className='home-contents'>
            <div className='txt'>
                <h1>Use our Service Guide to help find the info you need quickly</h1>
                <p>Join Lydia as she'll help you explore how to register your business, renew your business registration, obtain your Liscenses, apply for for importation permits</p>
            </div>
            <div className='tips-box'>
                <TipVideoBox title={videos[0].title} src={videos[0].src} subs={videos[0].subs}/>
                <TipVideoBox title={videos[1].title} src={videos[1].src} subs={videos[1].subs}/>
            </div>
        </main>
        <div className='other-services'>
                <div className='txt'>
                    <h1>Can't do without the old?</h1>
                    <p>In addition to MOCI online services, onsite registration at our Physical offices is still very possible and active</p>
                </div>
                <div className='service'>
                    <h2>Physical Location</h2>
                    <p>If your are still into visiting our office to do your registration in person, find a bereau that meets your needs</p>
                    <a>Find a bereau <FontAwesomeIcon icon={faArrowRightLong} /></a>
                </div>
                <div className='service'>
                    <h2>Still Indicisive?</h2>
                    <p>Find Answers from experienced counsellors that partner with us</p>
                    <a>Get legal advice <FontAwesomeIcon icon={faArrowRightLong} /></a>
                </div>
        </div>
        <div className='middle-banner'>
            <div className='heading'>
                <h2>Business Guides</h2>
                <p>The MOGI Commerce Handbook has all you need to know about the rules of the Liberian market, but what about the more practical considerations of everyday smooth business operations? Our specialized business tips can help</p>
            </div>
            <div className='tips-box'>
                <article className='tips'>
                    <h2>Wanna Invest In Liberia?</h2>
                    <p>Welcome to Liberia! Learn how you can register your business as a non-Liberian citizen, what you’ll need to get an Article of Incorporation, how to register to operate smoothly throug MOCI, and more</p>
                    <a>View the guide to Liberia Market</a>
                </article>
                <article className='tips'>
                    <h2>Renewal Guide</h2>
                    <p>Welcome to Liberia! Learn how you can register your business as a non-Liberian citizen, what you’ll need to get an Article of Incorporation, how to register to operate smoothly throug MOCI, and more</p>
                    <a>View the guide to Liberia Market</a>
                </article>
                <article className='tips'>
                    <h2>Chambers of Commerce</h2>
                    <p>Welcome to Liberia! Learn how you can register your business as a non-Liberian citizen, what you’ll need to get an Article of Incorporation, how to register to operate smoothly throug MOCI, and more</p>
                    <a>View the guide to Liberia Market</a>
                </article>
            </div>
            <div className='heading'>
                <h2>Latest News</h2>
                <p>Read the news as it unfolds.</p>
            </div>
            <div className='tips-box'>
                <article className='tips'>
                    <img src={require("../../assets/ganta-tour.jpg")} />
                    <h2>Hon. Amin Modad Visited Ganta City As Part Of His Assesment Tour</h2>
                    <p>Monday, Oct. 7, 2024</p>
                </article>
                <article className='tips'>
                    <img src={require("../../assets/boss.jpg")} />
                    <h2>Gol announces reduction in prices of petroleum products</h2>
                    <p>Wednesday, Sept 19, 2024</p>
                </article>
                <article className='tips'>
                    <img src={require("../../assets/announcements.jpg")} />
                    <h2>Public Service Announcement</h2>
                    <p>Monday, August 19, 2024</p>
                </article>
            </div>
        </div>
        <Footer />
    </div>
  )
}
