import React, { useEffect, useState } from 'react'
import Header from './components/header'
import Footer from './components/footer';
import "../css/web/about.css"
import { list } from '../_get/about-info';
import { Link } from 'react-router-dom';

export default function About() {
    var curIdx = localStorage.getItem("idx") | "0"
    const [currentTab, setCurrentTab] = useState(curIdx)
    const [title, setTitle] = useState("")
    const [img, setImg] = useState("")
    const [content, setContent] = useState("")
    const [ImgDes, setImgDes] = useState("")
    let paragraph = content.split("\n\n")
    useEffect(() => {
        setTimeout(() =>{
            var tabs = document.querySelectorAll(".tab-menu");
            tabs[currentTab].style.background = "#fff";
            setTitle(tabs[currentTab].textContent)
                    setContent(list[currentTab].content);
                    setImg(list[currentTab].img)
                    setImgDes(list[currentTab].imgdes);
            
            tabs.forEach((tab, index) => {
                tab.addEventListener("click", () => {
                    tabs[currentTab].style.background = "inherit"
                    tab.style.background = "#fff";
                    setCurrentTab(index)
                    setTitle(tab.textContent)
                    setContent(list[index].content);
                    setImg(list[index].img)
                    setImgDes(list[index].imgdes);
                    localStorage.setItem("idx", index);
                })
            })
        }, 10)
    })
    var screen = window.innerWidth;
  return (
    <div id="about">
      <Header pageName={"About"} screenWidth={screen} />
      <div className='content-container'>
        <h1>Get to know us well</h1>
        <div className='tabs-section'>
            <div className='tabs'>
                <div className='tab-menu'><p>Background</p></div>
                <div className='tab-menu'><p>Office Of The Minister</p></div>
                <div className='tab-menu'><p>Office Of The DMCA</p></div>
                <div className='tab-menu'><p>Assistant Minister, Central Administration</p></div>
                <div className='tab-menu'><p>Central Administration</p></div>
                <div className='tab-menu'><p>Bereau of Industry</p></div>
                <div className='tab-menu'><p>Small Business Administration</p></div>
            </div>
            <div className='tab-content-container'>
                <div className='tab-content'>
                    <h2>{title}</h2>
                    <div className='img-div'>
                        <img src={img} />
                        <p>{ImgDes}</p>
                    </div>
                    <p>{content}</p>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
