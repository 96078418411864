import React from 'react'
import "../../css/web/banner.css"
import reg from "../../../assets/registration.png"
import track from "../../../assets/track.jpg"
import Verify from "../../../assets/validate.png"
import Importation from "../../../assets/import.jpg"
import Lisence from "../../../assets/liscense.png"
import { Fade } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

export default function Banner({pageName, screen}) {
      
    const slides = [
        {title: "Easy Business Registration Online", img: Verify, btnTxt: "Start Now", href: "/"},
      //  {title: "I Can Easily Renew My Business Registration At Any Moment", img: Lisence, btnTxt: "Renew Now", href: "/"},
        //{title: "Do You need Lisence For your Buisness?", img: Importation, btnTxt: "Apply Now", href: "/"},
      //  {title: "Easy Business Registration Online", img: Verify, btnTxt: "Start Now", href: "/"},
        //{title: "Easy Business Registration Online", img: track, btnTxt: "Start Now", href: "/"},
       // {title: "Easy Business Registration Online", img: Lisence, btnTxt: "Start Now", href: "/"},
    ]
  return (
    <div id='banner'>
       <section>
            {slides.map((image, index) => {
                return(
                    <div key={index} className="each-fade">
                       <div className="image-container">
                            <img src={image.img} alt=""/>
                            <div className='text'>
                                <h1>{image.title}</h1>
                                <p>Do your online process Right Now!</p>
                                <button>{image.btnTxt}</button>
                            </div>
                       </div>
                    </div>
                )
            })}
           
        </section>
    </div>
  )
}
