import React from 'react'
import "../css/web/reg.css"
import TopBar from './portal/topBar'
import Welcome from './portal/welcome'
import BottomBar from './portal/bottomBar'
import App_Form from './portal/form'
import Form2 from './portal/boxButtonSlide'
import Form3 from './portal/form3'
import welcome from "../../assets/teacher.svg"
import { Link } from 'react-router-dom'

export default function Portal() {
  return (
    <div id='portal'>
        <TopBar />
        <div className='body'>
            <App_Form />
        </div>
        <BottomBar />
    </div>
  )
}
