import { faArrowRight, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

export default function TipVideoBox({src, title, subs}) {
  return (
    <div id='vid-container'>
        <iframe width="560" height="315" src={src + 'start=3'} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div className='txt'>
            <h3>{title}</h3>
            <p>{subs}</p>
        </div>
        <a>Start your journey <FontAwesomeIcon icon={faArrowRightLong} /></a>
    </div>
  )
}
